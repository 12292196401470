define("liquid-studios/controllers/index", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['tag'],
    tag: 'comm',
    actions: {
      setTag: function setTag(tag) {
        this.set('tag', tag);
      }
    }
  });

  _exports.default = _default;
});