define("liquid-studios/templates/components/video-spot", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "K7eEHmZ+",
    "block": "[[[10,0],[14,0,\"spot-preview\"],[15,5,[29,[\"background-image: url('\",[36,0],\"')\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"spot-information desktop-only\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"javascript:void;\"],[15,\"onclick\",[28,[37,1],[[30,0],\"togglePlayVideo\"],null]],[12],[1,\"\\n            \"],[10,\"svg\"],[14,0,\"spot-play\"],[12],[1,\"\\n                \"],[10,\"use\"],[14,\"xlink:href\",\"#icon-play\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[10,\"h2\"],[12],[1,[34,2]],[13],[1,\"\\n            \"],[10,2],[12],[1,[34,3]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"spot-information mobile-only\"],[14,6,\"javascript:void;\"],[15,\"onclick\",[28,[37,1],[[30,0],\"togglePlayVideo\"],null]],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n            \"],[10,\"h2\"],[12],[1,[34,2]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[33,5],[[[6,[39,6],null,[[\"onHide\"],[[28,[37,1],[[30,0],\"togglePlayVideo\"],null]]],[[\"default\"],[[[[1,\"            \"],[10,\"video\"],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[14,\"controls\",\"\"],[14,\"autoplay\",\"\"],[12],[1,\"\\n                \"],[10,\"source\"],[15,\"src\",[36,7]],[14,4,\"video/mp4\"],[12],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[13],[1,\"\\n\"]],[],false,[\"image\",\"action\",\"title\",\"description\",\"if\",\"playing\",\"light-box\",\"video\"]]",
    "moduleName": "liquid-studios/templates/components/video-spot.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});