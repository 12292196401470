define("liquid-studios/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.toRoute('about'), this.fromRoute('contact'), this.use('crossFade'));
  }
});