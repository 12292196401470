define("liquid-studios/templates/components/navigation-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QnLhnSBg",
    "block": "[[[10,0],[14,0,\"nav-menu\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,0,\"logo\"],[12],[1,\"\\n        \"],[10,\"use\"],[14,\"xlink:href\",\"#logo\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[6,[39,0],null,[[\"query\",\"route\"],[[28,[37,1],null,[[\"tag\"],[\"comm\"]]],\"index\"]],[[\"default\"],[[[[10,\"h2\"],[15,\"onclick\",[36,2]],[12],[1,\"Home\"],[13]],[]]]]],[1,\"\\n    \"],[6,[39,0],null,[[\"query\",\"route\"],[[28,[37,1],null,[[\"tag\"],[\"comm\"]]],\"index\"]],[[\"default\"],[[[[10,\"h2\"],[15,\"onclick\",[36,2]],[12],[1,\"Commercial\"],[13]],[]]]]],[1,\"\\n    \"],[6,[39,0],null,[[\"query\",\"route\"],[[28,[37,1],null,[[\"tag\"],[\"film\"]]],\"index\"]],[[\"default\"],[[[[10,\"h2\"],[15,\"onclick\",[36,2]],[12],[1,\"Film/TV\"],[13]],[]]]]],[1,\"\\n    \"],[6,[39,0],null,[[\"route\"],[\"about\"]],[[\"default\"],[[[[10,\"h2\"],[15,\"onclick\",[36,2]],[12],[1,\"About\"],[13]],[]]]]],[1,\"\\n    \"],[6,[39,0],null,[[\"route\"],[\"contact\"]],[[\"default\"],[[[[10,\"h2\"],[15,\"onclick\",[36,2]],[12],[1,\"Contact\"],[13]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"link-to\",\"-hash\",\"onNav\"]]",
    "moduleName": "liquid-studios/templates/components/navigation-menu.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});