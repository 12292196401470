define("liquid-studios/templates/contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8Ee70+f0",
    "block": "[[[10,0],[14,0,\"contact\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"contact-information\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"Contact Us\"],[13],[1,\"\\n        \"],[10,2],[12],[10,\"strong\"],[12],[1,\"Phone:\"],[13],[1,\" \"],[10,3],[14,6,\"tel:093670280\"],[12],[1,\"09 367 0280\"],[13],[13],[1,\"\\n        \"],[10,2],[12],[10,\"strong\"],[12],[1,\"Fax:\"],[13],[1,\" \"],[10,3],[14,6,\"tel:093670281\"],[12],[1,\"09 367 0281\"],[13],[13],[1,\"\\n        \"],[10,\"h3\"],[12],[1,\"Tamara O'Neill\"],[13],[1,\"\\n        \"],[10,\"h4\"],[12],[1,\"Executive Producer\"],[13],[1,\"\\n        \"],[10,2],[12],[10,3],[14,6,\"mailto:tam@liquidstudios.co.nz\"],[12],[1,\"tam@liquidstudios.co.nz\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"contact-map desktop-only\"],[12],[1,\"\\n        \"],[10,\"iframe\"],[14,\"width\",\"600\"],[14,\"height\",\"450\"],[14,\"frameborder\",\"0\"],[14,5,\"border:0\"],[14,\"src\",\"https://www.google.com/maps/embed/v1/place?q=Liquid%20Studios&key=AIzaSyBMgqlSv9M83bVMxvxFwqA3Y6mDSbNMz2A\"],[14,\"allowfullscreen\",\"\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "liquid-studios/templates/contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});