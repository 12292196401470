define("liquid-studios/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OtleeTrl",
    "block": "[[[41,[28,[37,1],[[33,2],\"comm\"],null],[[[1,\"    \"],[10,\"h2\"],[14,0,\"title\"],[12],[1,\"Here's some of our work:\"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[33,2],\"film\"],null],[[[1,\"    \"],[10,\"h2\"],[14,0,\"title\"],[12],[1,\"Our Film & TV Work:\"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[33,2],\"expe\"],null],[[[1,\"    \"],[10,\"h2\"],[14,0,\"title\"],[12],[1,\"Our Experiential Work:\"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]],[1,\"\\n\"],[10,0],[14,0,\"spot-holder\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"spot-list\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5,[\"videos\"]]],null]],null],null,[[[41,[28,[37,1],[[30,1,[\"tag\"]],[33,2]],null],[[[1,\"                \"],[10,\"li\"],[14,0,\"spot\"],[12],[1,[28,[35,6],null,[[\"image\",\"title\",\"description\",\"video\"],[[30,1,[\"image\"]],[30,1,[\"title\"]],[30,1,[\"description\"]],[30,1,[\"video\"]]]]]],[13],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"video\"],false,[\"if\",\"eq\",\"tag\",\"each\",\"-track-array\",\"model\",\"video-spot\"]]",
    "moduleName": "liquid-studios/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});