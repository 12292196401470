define("liquid-studios/controllers/application", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    showMenu: false,
    actions: {
      toggleMenu: function toggleMenu() {
        this.set('showMenu', !this.showMenu);
      }
    }
  });

  _exports.default = _default;
});