define("liquid-studios/components/video-spot", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    playing: false,
    actions: {
      togglePlayVideo: function togglePlayVideo() {
        this.set('playing', !this.playing);
      }
    }
  });

  _exports.default = _default;
});