define("liquid-studios/templates/components/light-box", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Tflwl8Lf",
    "block": "[[[10,0],[14,0,\"light-box\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[36,0]],[14,0,\"close-button\"],[12],[1,\"\\n        \"],[10,\"svg\"],[12],[1,\"\\n            \"],[10,\"use\"],[14,\"xlink:href\",\"#icon-cross\",\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"onHide\",\"yield\"]]",
    "moduleName": "liquid-studios/templates/components/light-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});